:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Poppins', serif;
  --font-size-custom: 14px;
  font-size: var(--font-size-custom) !important;
}

body {
  line-height: 1.35 !important;
  font-family: 'Poppins', serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: calc(var(--font-size-custom, 14px) * 1.6428);
}
h1.ant-typography {
  font-size: calc(var(--font-size-custom, 14px) * 1.6428);
}

h2 {
  font-size: calc(var(--font-size-custom, 14px) * 1.5);
}
h2.ant-typography {
  font-size: calc(var(--font-size-custom, 14px) * 1.5);
}

h3 {
  font-size: calc(var(--font-size-custom, 14px) * 1.357);
}
h3.ant-typography {
  font-size: calc(var(--font-size-custom, 14px) * 1.357);
}

h4 {
  font-size: calc(var(--font-size-custom, 14px) * 1.214);
}
h4.ant-typography {
  font-size: calc(var(--font-size-custom, 14px) * 1.214);
}

h5 {
  font-size: var(--font-size-custom, 14px);
}
h5.ant-typography {
  font-size: var(--font-size-custom, 14px);
}

h6 {
  font-size: var(--font-size-custom, 14px);
}
h6.ant-typography {
  font-size: var(--font-size-custom, 14px);
}

.ant-menu,
.ant-select,
.ant-btn,
.ant-breadcrumb,
.ant-table,
.ant-pagination,
.ant-card,
.ant-form label,
.ant-input-lg,
.ant-input,
div,
p,
.ant-radio-wrapper,
.ant-steps-item-title {
  font-size: var(--font-size-custom, 14px) !important;
}

.ant-menu-item {
  height: calc(var(--font-size-custom, 14px) * 2.857) !important;
}

.ant-btn {
  padding: calc(var(--font-size-custom, 14px) * 0.6785)
    calc(var(--font-size-custom, 14px) * 1.071);
  height: calc(var(--font-size-custom, 14px) * 2.857);
}

.ant-input {
  height: calc(var(--font-size-custom, 14px) * 2.857);
}

.ant-input-wrapper .ant-input,
.ant-input-group .ant-input {
  height: calc(var(--font-size-custom, 14px) * 2.857);
}

.ant-select-arrow {
  font-size: calc(var(--font-size-custom, 14px) * 0.857);
}

.ant-card-head,
.ant-card-head-title,
.ant-btn-icon-only > * {
  font-size: calc(var(--font-size-custom, 14px) * 1.1428) !important;
}

.ant-input-lg {
  padding: calc(var(--font-size-custom, 14px) * 0.464)
    var(--font-size-custom, 14px);
}
.ant-input,
.ant-input-affix-wrapper > input.ant-input {
  padding: calc(var(--font-size-custom, 14px) * 0.464)
    var(--font-size-custom, 14px);
}

.ant-btn-icon-only {
  width: calc(var(--font-size-custom, 14px) * 2.857);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-selector {
  min-height: calc(var(--font-size-custom, 14px) * 2.857);
  height: auto;
  padding: calc(var(--font-size-custom, 14px) - 14px) 14px;
}

.ant-dropdown-menu-item {
  font-size: var(--font-size-custom, 14px);
}

.ant-input-affix-wrapper {
  padding: 0;
}

.ant-input-password {
  height: calc(var(--font-size-custom, 14px) * 2.857) !important;
  .ant-input {
    height: auto !important;
  }
}
