.custom-list-bg {
  .ant-row {
    background: rgba(3, 88, 140, 0.02);
    padding: 15px 24px;
  }
  .ant-row:nth-child(odd) {
    background: #fff;
    padding: 15px 24px;
  }

  .vl-title::before {
    content: ': \00a0 \00a0';
  }
}
