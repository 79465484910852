@import '../../../shared/styles/variable';

//for removing the empty space above the sidebar
.app-main-sidebar-menu .ant-menu-item-group-title {
  display: none;
}

// .main-content-view {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   padding: 0;
//   min-height: auto;

//   @media screen and (min-width: @screen-md) {
//     padding: 0;
//   }
// }
