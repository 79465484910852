@import '../../../../shared/styles/variable';
@import '../../../../shared/styles/mixin';

.app-main-footer {
  border-top: 1px solid rgb(241, 241, 241);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(var(--font-size-custom, 14px) * 0.3928)
    calc(var(--font-size-custom, 14px) * 1.428);
  color: @text-color;
  background-color: @white;

  @media screen and (min-width: @screen-md) {
    padding: calc(var(--font-size-custom, 14px) * 1.428)
      calc(var(--font-size-custom, 14px) * 2.2857);
  }

  & p {
    margin-bottom: 0;
  }
}

.footer-btn-view {
  margin-left: auto;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }
}

.footer-btn {
  .box-shadow(none);
  height: auto;
}
