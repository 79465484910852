@import '../../../shared/styles/variable';

.card {
  position: relative;
  box-shadow: @card-shadow;
  border-radius: @card-radius;
  display: flex;
  flex-direction: column;

  & > .ant-card-head {
    min-height: 10px;
    font-weight: @font-weight-bold;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 0 none;
    margin-bottom: 0;

    & .ant-card-head-title {
      line-height: 1.4993rem;
      padding-bottom: 4px;
    }

    & .ant-card-extra {
      padding-bottom: 4px;

      & a {
        color: @secondary-color;
      }
    }
  }

  & > .ant-card-body {
    padding: 16px 20px;
  }

  &.heightFull {
    height: 100%;

    & .ant-card-body {
      flex: 1;
    }
  }

  & .ant-card-actions {
    background-color: transparent;
  }
}

.card-outer-title {
  font-size: @font-size-lg;
  margin-bottom: 16px;
  font-weight: @font-weight-bold;
  color: @text-color;
}

.no-card-space {
  & .ant-card-body {
    padding: 0;
  }
}

.no-card-space-ltr-rtl {
  & .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
